'use client'

import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    chakra,
    Container,
    Flex,
    Heading,
    Image,
    SimpleGrid,
    Stack,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import { AddIcon, ArrowBackIcon, EmailIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

export default function GridBlurredBackdrop() {
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch data from API
        async function fetchData() {
            try {
                const response = await fetch('https://miniworld.ohgame.io.vn/server/getasset.php?page=1'); // Replace with your API endpoint
                const result = await response.json();
                setData(result.records); // Adjust according to your API response structure
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);
    const handleDownload = async (fileId) => {
        try {
            const response = await fetch(`https://miniworld.ohgame.io.vn/server/download.php?id=${fileId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/octet-stream',
                },
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = fileId+".zip"; // Optionally specify the filename
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            } else {
                console.error('Download failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };
    return (
        <Flex
            textAlign={'center'}
            pt={10}
            justifyContent={'center'}
            direction={'column'}
            width={'full'}
            overflow={'hidden'}>
                <Stack direction='row' alignSelf={"center"} spacing={4}>
                <Button leftIcon={<ArrowBackIcon />} colorScheme='gray' variant='solid' onClick={()=>{navigate("../")}}>
    Trang chủ
  </Button>
                <Button leftIcon={<AddIcon />} colorScheme='teal' variant='solid' onClick={()=>{navigate("../upload")}}>
    Chia sẻ tài nguyên
  </Button>
  
 
</Stack>
            <Box width={{ base: 'full', sm: 'lg', lg: 'xl' }} margin={'auto'}>
                <chakra.h3
                    fontFamily={'Work Sans'}
                    fontWeight={'bold'}
                    fontSize={20}
                    textTransform={'uppercase'}
                    color={'purple.400'}>
                    People love us
                </chakra.h3>
            </Box>
            <SimpleGrid columns={{ base: 1, xl: 2 }} spacing={'20'} mt={16} mb={16} mx={'auto'}>
                {data.map(item => (
                    <Card
                        key={item.id}
                        direction={{ base: 'column', sm: 'row' }}
                        overflow='hidden'
                        variant='outline'
                    >
                        <Image
                            objectFit='cover'
                            maxW={{ base: '100%', sm: '200px' }}
                            src={"https://miniworld.ohgame.io.vn/server/"+item.avatar} // Adjust according to your data
                            alt={item.name}
                        />

                        <Stack>
                            <CardBody>
                              
                                <Heading size='md'>{item.name}</Heading>
                                <Text py='2'>
                                    {item.description}
                                </Text>
                                <Text>
                                    Lượt tải: {item.downloads}
                                </Text>
                                <Text>
                                    Cập nhật: {item.upload_time}
                                </Text>
                            </CardBody>

                            <CardFooter>
                                <Button variant='solid' colorScheme='blue'  onClick={() => handleDownload(item.id)}>
                                    Tải
                                </Button>
                                <Button variant='outline' colorScheme='red'  onClick={() => navigate(`../file/${item.id}`)}>
                                    Chi tiết
                                </Button>
                            </CardFooter>
                        </Stack>
                    </Card>
                ))}
            </SimpleGrid>
        </Flex>
    );
}
