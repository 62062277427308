import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Textarea, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

function FileUploadPage() {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState(0);
  const [avatar, setAvatar] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleAvatarChange = (event) => {
    setAvatar(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('avatar', avatar);

    try {
      const response = await fetch('https://miniworld.ohgame.io.vn/server/upload.php', { // Adjust the endpoint as needed
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        alert('File uploaded successfully!');
        navigate(`../file/${data.id}`); // Redirect to the file details page with the id
      } else {
        alert('File upload failed.');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('An error occurred during file upload.');
    }
  };

  return (
    <Box maxW="md" mx="auto" mt={10} p={5} borderWidth={1} borderRadius="lg">
      <form onSubmit={handleSubmit}>
        <VStack spacing={5}>
          <FormControl>
            <FormLabel htmlFor="name">Name</FormLabel>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="description">Description</FormLabel>
            <Textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="price">Price</FormLabel>
            <Input
              type="number"
              id="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="avatar">Avatar</FormLabel>
            <Input
              type="file"
              id="avatar"
              accept=".jpg, .jpeg, .png, .gif"
              onChange={handleAvatarChange}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="file">Choose file</FormLabel>
            <Input
              type="file"
              id="file"
              accept=".zip, .rar"
              onChange={handleFileChange}
              required
            />
          </FormControl>
          <Button type="submit" colorScheme="blue" isDisabled={!file || !avatar}>
            Upload File
          </Button>
          <Button onClick={() => { navigate('../UIAsset'); }} colorScheme="gray">
            Huỷ
          </Button>
        </VStack>
      </form>
    </Box>
  );
}

export default FileUploadPage;
