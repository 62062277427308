'use client'

import { InfoIcon, SunIcon } from '@chakra-ui/icons'
import { Stack, Flex, Button, Text, VStack, useBreakpointValue, Badge, MenuButton, MenuItem, MenuList, Menu, ListIcon } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';

export default function WithBackgroundImage() {
  const navigate = useNavigate();
  return (
    <Flex
      w={'full'}
      h={'100vh'}
      backgroundImage={
        'url(https://hwimage-cdn.mini1.cn/a/20240524/94906c3d1ae010e9b009686021f76abf.jpg?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80)'
      }
      backgroundSize={'cover'}
      backgroundPosition={'center center'}>
      <VStack
        w={'full'}
        justify={'center'}
        px={useBreakpointValue({ base: 4, md: 8 })}
        bgGradient={'linear(to-r, blackAlpha.600, transparent)'}>
        <Stack maxW={'2xl'} align={'flex-start'} spacing={6}>
          <Text
            color={'white'}
            fontWeight={700}
            lineHeight={1.2}
            fontSize={useBreakpointValue({ base: '3xl', md: '4xl' })}>
            DevWorld Online <Badge colorScheme='red'>TEST</Badge>
           
          </Text>
          <Text  color={'white'}
            lineHeight={1.2}>Hiện tại chức năng đang phát triển nên chức nâng mới sẽ được cập nhật từ từ.</Text>
          <Stack direction={'row'}>
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton isActive={isOpen} as={Button} rightIcon={<InfoIcon />}>
                    {isOpen ? 'Chức Năng' : 'Chức Năng'}
                  </MenuButton>
                  <MenuList>
                  
                    <MenuItem onClick={() => navigate('/UIAsset')}>Tài Nguyên UI  <Badge colorScheme='green'>Free</Badge></MenuItem>
                    <MenuItem onClick={() => window.location.href="https://lua.ohgame.io.vn/"}>Lập trình & Chia sẻ Script  <Badge colorScheme='green'>Free</Badge></MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
            <Button
              bg={'whiteAlpha.300'}
              rounded={'full'}
              color={'white'}
              _hover={{ bg: 'whiteAlpha.500' }}
              onClick={()=> window.location.href = "https://me.momo.vn/DevWordTool"}>
              Donate
            </Button>
          </Stack>
          
        </Stack>
      </VStack>
    </Flex>
  )
}