
import './App.css';
import GridBlurredBackdrop from './page/Asset.js';
import FileInfoPage from './page/FileInfo.js';
import FileUploadPage from './page/FileUploadPage.js';
import GoogleAdsScript from './page/GoogleAdsScript.js';
import WithBackgroundImage from './page/WithBackgroundImage.js';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
function App() {
  return (

    <BrowserRouter>
    
      <Routes>
      <Route path="/" element={<WithBackgroundImage />} />
      <Route path="/UIAsset" element={<GridBlurredBackdrop />} />
      <Route path="/upload" element={<FileUploadPage />} />
      <Route path="/file/:id" element={<FileInfoPage />} /> 
    </Routes>
</BrowserRouter>
  )
}

export default App;
