import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Text, Spinner, VStack, Heading, Button } from '@chakra-ui/react';

function FileInfoPage() {
    const navigate = useNavigate();
  const { id } = useParams();
  const [fileInfo, setFileInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFileInfo = async () => {
      try {
        const response = await fetch(`https://miniworld.ohgame.io.vn/server/get_file_info.php?id=${id}`);
        const data = await response.json();

        if (data.status === 'success') {
          setFileInfo(data.file_info);
        } else {
          setError(data.message);
        }
      } catch (err) {
        setError('An error occurred while fetching the file information.');
      } finally {
        setLoading(false);
      }
    };

    fetchFileInfo();
  }, [id]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  return (
    <Box maxW="md" mx="auto" mt={10} p={5} borderWidth={1} borderRadius="lg">
      <VStack spacing={5}>
        <Heading size="lg">{fileInfo.name}</Heading>
        <Text>Description: {fileInfo.description}</Text>
       
        <Text>Downloads: {fileInfo.downloads}</Text>
    
        {fileInfo.avatar_path && <img src={"https://miniworld.ohgame.io.vn/server/"+fileInfo.avatar_path} alt="Avatar" />}
        {fileInfo.file_path && <a href={"https://miniworld.ohgame.io.vn/server/"+fileInfo.file_path} download>Download File</a>}
        <Button onClick={()=>{navigate('../UIAsset')}} colorScheme="gray" >
            Huỷ
          </Button>
      </VStack>
    </Box>
  );
}

export default FileInfoPage;
